  
  body {
    margin: 0;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
  
  .material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
  }
  
  .material-symbols-fill {
    font-variation-settings: 'FILL' 1;
  }
  